import React, { Component } from "react";
import { translate  } from './../../helpers/intl_helpers';

import NormalDataTable from './../NormalDataTable';

class CustomerList extends Component {
	/** For manage action button click event */
	customAction = (actionKey, rowData) => {
		if(actionKey === "view_job" && rowData._id){
			this.props.history.push('/customer/' + rowData._id);
		}
	}

	render() {
		let pageStats = {
			title 	:	translate("customer.customers"),
			columns :	[
				{name: 	translate("customer.customer_code"), selector: "_id", width: "30%", defaultSort: true, sortable: true, direction: "asc"},
				{name: 	translate("customer.customer_name"), selector: "customer_name", width: "30%", sortable: true },
				{name: 	translate("customer.owner_name"),    selector: "owner_name", width: "25%", sortable: true},
				{name: 	translate("customer.count"),   		 selector: "job_count", width: "25%", sortable: true},
				{name: 	translate("system.action"), width: "15%", custom_action: this.customAction, actions: [{key: "view_job"}]},
			],
			search : [
				{name: translate("customer.customer_code"), selector: "customer_code", 	input_type: "text"},
				{name: translate("customer.customer_name"), selector: "customer_name", 	input_type: "text"},
			]
		}

		return <>
			<NormalDataTable pageStats={pageStats}  apiParm={{model: 'customer', method: 'customerList'}} />
		</>
	}
}
export default CustomerList;
