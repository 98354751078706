/* API request complete path */
export const API_URL = process.env.REACT_APP_HOST+'/';

/* Website Url */
export const WEBSITE_URL = process.env.REACT_APP_HOST+'/';

/* Website Url */
export const WEBSITE_IMG_URL = WEBSITE_URL+'images/';

/* Key for API authentication */
export const API_HEADER_AUTH_KEY = process.env.REACT_APP_API_HEADER_AUTH_KEY;

/* Key for GOOGLE API key */
export const GOOGLE_API_KEY	=	process.env.REACT_APP_GOOGLE_API_KEY;

/* Languages configurations */
export const ENGLISH_LANGUAGE_MONGO_ID	= "5a3a13238481824b077b23ca";
export const ENGLISH_LANGUAGE_CODE		= "en";

/* Default language configurations */
export const DEFAULT_LANGUAGE_CODE		= ENGLISH_LANGUAGE_CODE;
export const DEFAULT_LANGUAGE_ID		= ENGLISH_LANGUAGE_MONGO_ID;

/** For currency symbol */
export const CURRENCY_CODE   = 'USD';
export const CURRENCY_SYMBOL = '$';

/** API status */
export const STATUS_SUCCESS = "success";
export const STATUS_ERROR 	= "error";

export const ROUND_PRECISION = 2;

/** Date format **/
export const AM_PM_FORMAT_WITH_DATE		=	"YYYY/MM/DD hh:mm A";
export const RATING_DATE_TIME_FORMAT	=	"DD/MM/YY";
export const JOB_DATE_FORMAT	        =	"DD/MM/YYYY";
export const DATERANGE_DATE_TIME_FORMAT	=	"MM/DD/YYYY";
export const CALENDAR_DATE_TIME_FORMAT	=	"YYYY-MM-DD 23:59:59";
export const DATE_PICKER_DATE_FORMAT	=	"MM/dd/YYYY";
export const TIME_PICKER_FORMAT         =	"hh:mm aa";
export const DEFAULT_DATE_FORMAT	    =	"MM/dd/YYYY";
export const DATE_RANGE_PICKER_FORMAT   =	"MM/dd/yyyy";
export const REPORT_DATE_FORMAT	    =	"MM/DD/YYYY";
export const CALENDAR_START_DATE_TIME_FORMAT	=	"YYYY-MM-DD 00:00:00";


/** Time Format **/
export const AM_PM_FORMAT_WITH_TIME		=	"hh:mm a";

export const ACTIVE     =   1;
export const INACTIVE   =   0;

export const RADIO_YES  =   1;
export const RADIO_NO   =   0;

/** Subcontractor Agreement Type */
export const MSA = "msa";
export const SINGLE_SUBCONTRACTOR = "single_subcontractor";

/** SHIFT status */
export const SHIFT_DAY  = "day";
export const SHIFT_NIGHT = "night";

/** Departments */
export const OSP_DEPARTMENT      =   "osp";
export const ISP_DEPARTMENT      =   "isp";
export const ELEC_DEPARTMENT     =   "elec";
export const HDD_DEPARTMENT      =   "hdd";
export const FIBER_DEPARTMENT    =   "fiber";
export const CTR_DEPARTMENT      =   "ctr";
export const OFFICE_DEPARTMENT   =   "office";
// export const JBLM_DEPARTMENT     =   "jblm";


/** Department dropdown */
export const DEPARTMENT_DROPDOWN = [
    { value: CTR_DEPARTMENT,    label: 'CTR' },
    { value: HDD_DEPARTMENT,    label: 'HDD' },
    { value: ISP_DEPARTMENT,    label: 'ISP' },
    { value: OSP_DEPARTMENT,    label: 'OSP' },
    { value: ELEC_DEPARTMENT,   label: 'ELEC' },
    { value: FIBER_DEPARTMENT,  label: 'FIBER' },
    // { value: OFFICE_DEPARTMENT, label: 'OFFICE' },
    // { value: JBLM_DEPARTMENT,   label: 'JBLM' },
];

export const PAGING_LENGTH_MENU = [10,20,50,100,1000];

/**EMPLOYEE PERMISSION LEVEL DROPDOWN */
export const ADMIN 		     =	"admin";
export const READ_ONLY 	     =	"read_only";
export const FOREMAN         =	"foreman";
export const PROJECT_MANAGER =	"project_manager";
export const DISPATCHER	     =	"dispatcher";
export const GENERIC_TV_LOGINS=	"generic_tv_logins";

export const PERMISSION_LEVEL_DROPDOWN = [
    { value: ADMIN,             label: 'Super Admin' },
    { value: DISPATCHER,        label: 'Admin' },
    { value: PROJECT_MANAGER,   label: 'Dispatcher' },
    { value: FOREMAN,           label: 'Editor' },
    { value: READ_ONLY,         label: 'Read Only' },
    { value: GENERIC_TV_LOGINS, label: 'System User Account' },
];

/** Employee Role list */
export const CREW  = "crew";
export const EMPLOYEE_ROLE_DROPDOWN = [
    { value: DISPATCHER,label: 'Admin' },
    { value: PROJECT_MANAGER,   label: 'Dispatcher' },
    { value: FOREMAN,   label: 'Editor' },
    { value: CREW,      label: 'Crew' }
];

export const ALLOWED_MASTER_LIST = ["location","driver_tier_level","equipment_tier_level","hdd_drill_size","specialized_tools_ctr","headend_hub_location","job_title","badges","fiber_task","scope_of_work_type","hdd_locator_type",'surface_type','locate_type','skill_set'];


export const MASTER_DRIVER_TIER_LEVEL = 'driver_tier_level';
export const MASTER_LOCATION          = 'location';

/** Calendar Static Role id */
export const CALENDAR_PTO 			= 	"664db774f836b6d91fcb3639";
export const CALENDAR_YARD 			= 	"664db779f836b6d91fcb363b";
export const CALENDAR_SUB_CONTRACTOR =	"664db78af836b6d91fcb363d";

export const CALENDAR_STATIC_ROLE  = {
	[CALENDAR_YARD] 			: {name: "Yard", type: "yard"},
	[CALENDAR_PTO]  			: {name: "PTO", type: "pto"},
	[CALENDAR_SUB_CONTRACTOR] 	: {name: "Subcontractor", type: "subcontractor"},
};


/** System export format */
export const EXPORT_PDF  =  "pdf" ;
export const EXPORT_CSV  =  "csv" ;

/** Dashboard filter status */
export const CURRENT_DAY  = "day";
export const CURRENT_WEEK  = "week";
export const CURRENT_MONTH  = "month";

/**EMPLOYEE JOB FIELD LEVEL DROPDOWN */
export const JOB_TYPE_FIELD 	=	"field";
export const JOB_TYPE_OFFICE 	=	"office";

export const JOB_TITLE_TYPE_DROPDOWN = [
    { value: JOB_TYPE_FIELD,     label: 'Field' },
    { value: JOB_TYPE_OFFICE,    label: 'Office' }
];


export const ACTIVE_DEACTIVE_DROPDOWN = [
	{value: String(ACTIVE), label: "Active"},
	{value: String(INACTIVE), label: "Inactive"}
];

export const USER_ACTIVE_DEACTIVE_DROPDOWN = [
	{value: String(ACTIVE), label: "Active"},
	{value: String(INACTIVE), label: "Terminated"}
];

export const IS_RENTAL_DROPDOWN = [
	{value: String(ACTIVE), label: "Yes"},
	{value: String(INACTIVE), label: "No"}
];

export const PAID_UNPAID_DROPDOWN = [
	{value: "paid", label: "PTO"},
	{value: "unpaid", label: "Non PTO"}
];

export const AGREEMENT_TYPE_DROPDOWN = [
	{value: MSA, label: "MSA"},
	{value: SINGLE_SUBCONTRACTOR, label: "Single Subcontractor"}
];

export const EQUIPMENT_STATUS_DROPDOWN = [
	{value: "A", label: "Active"},
	{value: "I", label: "Inactive"}
];

export const ALLOWED_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png"];