import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import {DEPARTMENT_DROPDOWN ,ACTIVE_DEACTIVE_DROPDOWN, ACTIVE, IS_RENTAL_DROPDOWN}  from './../../config/global_constants';
import { callBackendAPI,getRandomString, upperCaseOfName} from './../../helpers/common';
import { translate  } from './../../helpers/intl_helpers';
import Cookies from 'universal-cookie';

import NormalDataTable from './../NormalDataTable';
import BulkEquipmentModal from './BulkEquipments';

export default class EquipmentList extends Component {
	constructor(props) {
        super(props);

		this.state = {
			showBulkModel 	: false,
			searchStats 	: 	{is_active :{value : ACTIVE, label:"Active"}},
			tableKey 		: 	getRandomString()
		}

		this.customAction 	=	this.customAction.bind(this);
		this.customRender 	= 	this.customRender.bind(this);
		this.hideModal		= 	this.hideModal.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial conditions rules.
	 */
	async componentDidMount() {
		this.getCookiesData();
    }

	/**
     * For get cookies details
     */
	 getCookiesData = async () => {
        const cookies = new Cookies();
        const userCookie = cookies.get('user_details');
        const { department, location } = userCookie;

        let searchStats = {department: department ? department.map(dept => ({ value: dept, label: dept })) :null};
        if(location) searchStats.location = location;
        // this.setState({ searchStats: searchStats,  tableKey: getRandomString() });
    	this.setState({  tableKey: getRandomString() });
    }
	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type, searchStats }) {
        try{
			let apiReq = { model: 'home', method: 'masterData', name: inputValue ? inputValue : ''};
            if(type === "location") 	apiReq.type = "location";
			if(type === "assignee_name") apiReq = { model: 'calendar', method: 'employeeList', is_crew_member: true, emp_name: inputValue ? inputValue : '', current_page: page};

			/** Add page number */
            apiReq.current_page = page;

			if(type === "department"){
				let tmpLocations = searchStats && searchStats.location  ? searchStats.location  :[];
				let tmpDepartment = {};
				if(tmpLocations && tmpLocations.length){
					tmpLocations.forEach(element => {
						if(element.department && element.department.length){
							element.department.forEach(childKey => {
								DEPARTMENT_DROPDOWN.forEach(subElement=>{
									if(childKey  === subElement.value){
										tmpDepartment[subElement.value] = subElement;
									}
								});
							});
						}
					});
				}
				return {options: Object.values(tmpDepartment), hasMore: true, additional: {page: page, type: type }};
			}

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/** Manage table custom render */
	customRender = (actionKey, rowData, completeApiRes) => {
		if(!actionKey || !rowData || !rowData._id)  return "";

		let element = "";
		if(actionKey === "equipment_code_type"){
			element	=	rowData.equipment_code	?	rowData.equipment_code  		:"";
			element += 	rowData.equipment_type	? 	" / "+rowData.equipment_type  	:"";
		}
		if(actionKey === "equipment_make_model"){
			element	=	rowData.equipment_make	?	rowData.equipment_make  		:"";
			element += 	rowData.equipment_model	? 	" / "+rowData.equipment_model  	:"";
		}
		if(actionKey === "status"){
			let status = (rowData.is_active)	? 	"Active" : "Inactive";
			element=<span className ={rowData.is_active ? "text-success" : "text-danger"}>
				{status}
			</span>
		}
		if(actionKey === "is_rental"){
			let status = (rowData.is_rental)	? 	"Yes" : "No";
			element=<span className ={rowData.is_rental ? "text-success" : "text-danger"}>
				{status}
			</span>
		}
		if(actionKey === "department"){
			let department	= rowData.department ? rowData.department :[];
			DEPARTMENT_DROPDOWN.filter(data=>{
				if(department.indexOf(data.value)!== -1) element += ", " +data.label;
				return null;
			});
			element = element.slice(1);
		}

		if(actionKey === "assignee_name" && rowData.assignee_name){
			element = rowData.assignee_name;
		}
		if(actionKey === "location" && rowData.location_title){
			element = rowData.location_title;
		}
		if(actionKey === "image"){
			let completeImgUrl 	= completeApiRes.file_url 	? completeApiRes.file_url 	: "";
			let imgName 		= rowData.image 			? rowData.image 			: "";
			let employeeName	= upperCaseOfName(rowData.equipment_code,rowData.equipment_type);

			let nameHtml =<span className="sort-name greenbg">
				{employeeName}
			</span>;

			let employeeImg  = (imgName) ? completeImgUrl+imgName : "";
			element  		 = (imgName) ? <img src={employeeImg} alt="Img"  className="sort-name" onClick = {()=>{this.handleImageClick(employeeImg)}}/> : nameHtml;
		}
		return element;
	};// end customRender()

	/** For manage action button click event */
	customAction = (actionKey, rowData) => {
		if(actionKey === "add") this.props.history.push('/equipments/add');
		if(actionKey === "edit" && rowData._id) this.props.history.push('/equipments/update/' + rowData._id);
		if(actionKey === "bulk") this.setState({showBulkModel: true,});
	}

	/** Manage model hide event */
	hideModal = () => {
		this.setState({showBulkModel: false, tableKey: 	getRandomString()});
	}

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}

	render() {
		const {showBulkModel,lightBoxShow, selectedImage,searchStats,tableKey} = this.state;

		let pageStats = {
			searchStats :	searchStats,
			pageType :	"equipement",
			title 	:	translate("equipments.heading_title"),
			headerLinks	: [
				{name: translate("equipments.add_equipment"), selector: "back", clickEvent:()=> this.customAction('add') },
				{name: translate("equipments.bulk_equipment"),selector: "bulk", clickEvent:()=> this.customAction('bulk') }
			],
			columns :	[
				{name: translate("employee.image"), selector: "image", customRender: this.customRender, width: "13%"},
				{name: translate("equipments.equipment_code"), selector: "equipment_code",sortable: true, defaultSort: true, direction: "asc", width: "20%"},
				{name: translate("equipments.equipment_type"), selector: "equipment_type", sortable: true,width: "20%"},
				{name: translate("equipments.description"), selector: "description", sortable: true, width: "10%"},
				{name: translate("equipments.equipment_make_model"), selector: "equipment_make_model", customRender: this.customRender, width: "15%"},
				{name: translate("employee.location"),  selector: "location", customRender: this.customRender, width: "15%"},
				{name: translate("employee.department"), selector: "department", customRender: this.customRender, width: "10%"},
				{name: translate("employee.assignee_name"), selector: "assignee_name", customRender: this.customRender, width: "10%"},
				{name: translate("equipments.status"), selector: "status", width: "5%",  customRender: this.customRender },
				{name: translate("equipments.is_rental"), selector: "is_rental", width: "5%",  customRender: this.customRender },
				{name: translate("system.action"), selector: "", width: "20%", custom_action: this.customAction, actions: [{key: "edit"}]},
			],
			search :	[
				{name: translate("equipments.search_by_code_description_or_assignee"), 	selector: "equipment_code", input_type: "text", showLabel: true, inputClass: "input-with-label"},
				{name: translate("equipments.search_by_type_make_or_model"), selector: "equipment_type", input_type: "text", showLabel: true, inputClass: "input-with-label"},
				{name: translate("employee.location"), selector: "location", input_type: "asyncPaginate", isMultiple: true, child_selector:"department"},
				{name: translate("employee.department"), selector: "department", input_type: "asyncPaginate", isMultiple: true, parent_selector:"location" },
				{name: translate("equipments.status"), selector: "is_active", input_type: "select", showLabel:true, options: ACTIVE_DEACTIVE_DROPDOWN , defaultValue : {value : ACTIVE, label:"Active"} },
				{name: translate("equipments.is_rental"), selector: "is_rental", input_type: "select", showLabel:true, options: IS_RENTAL_DROPDOWN },
			]
		}
		return <>
			<NormalDataTable key={tableKey} pageStats={pageStats} apiExport={{model: 'equipment', method: 'equipmentExports',allRecords : true}} apiParm={{model: 'equipment', method: 'list'}}  getDropDownList={this.getDropDownList}/>

			{showBulkModel ?
				<BulkEquipmentModal hideModal={this.hideModal} />
			:null}
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={selectedImage}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}
		</>
	}
}