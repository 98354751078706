import React, { Component } from "react";
import { translate  } from './../../helpers/intl_helpers';
import { getRandomString, customConfirmBox, callBackendAPI, reFormatSuccess  } from './../../helpers/common';
import NormalDataTable from './../NormalDataTable';
import {ALLOWED_MASTER_LIST, PROJECT_MANAGER}  from '../../config/global_constants';
import Auth from './../../helpers/Auth';

import AddEditForm from './AddEdit';

class MasterList extends Component {

	constructor(props) {
        super(props);

		const { dropdown_type } = props.match.params;
        const isAllowedParams   = ALLOWED_MASTER_LIST.includes(dropdown_type);

        this.state = {
			showModel		: false,
			dropdownType 	: dropdown_type,
			isAllowedParams : isAllowedParams,
			selectedOption  : [],
			tableKey 		: getRandomString(),
        };

		this.toggleModal 		= this.toggleModal.bind(this);
		this.handleDeleteJobTitle 	=	this.handleDeleteJobTitle.bind(this);
		this.refreshDataTable   = this.refreshDataTable.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial data fetch and manage initial conditions rules.
	 */
	componentDidMount() {
		if (!this.state.isAllowedParams) this.props.history.push('/');
	}

	/**
	 * This function invoked immediately after updating occurs. This method is not called for the initial render.
	 * Its use when master type is changed
	 */
	componentDidUpdate(prevProps) {
        const { dropdown_type }   = this.props.match.params;
		const { isAllowedParams } = this.state;

        if (dropdown_type !== prevProps.match.params.dropdown_type) {
            if (!isAllowedParams) {
                this.props.history.push('/');
                return;
            }
            this.setState({
                dropdownType	: dropdown_type,
                isAllowedParams : isAllowedParams,
            }, this.refreshDataTable);
        }
    }

	/** Refresh listing data-table */
	refreshDataTable() {
		this.setState({
			tableKey : getRandomString(),
		});
    }

	/** Function to handle delete button click */
	handleDeleteJobTitle = (masterId) => {
		const confirmation = customConfirmBox(translate('master.you_want_to_delete_the_job_title'));
		confirmation.then((confirmed) => {
			if(confirmed) {
				callBackendAPI([{ model: 'master', method: 'deleteOne', master_id: masterId }]).then((response) => {
					if(response.success) {
						reFormatSuccess(response.data[0].message);
						this.setState({tableKey: getRandomString()});
					}
				}).catch(err => console.log(err));
			}
		});
	}

	/** For manage action button click event */
	customAction = (actionKey, rowData) => {
		if(actionKey === "edit" && rowData._id){
			this.setState({
				showModel :	true,
				masterId  : rowData._id
			});
		}else if(actionKey === "add"){
			this.setState({
				showModel: true
			});
		}else if(actionKey === "delete" && rowData._id) {
			this.handleDeleteJobTitle(rowData._id)
		}
	}

	/** Manage table custom render */
	customRender = (actionKey, rowData) => {
		if(!actionKey || !rowData || !rowData._id)  return "";

		const {dropdownType} = this.state;
		let element = "";
		if(actionKey === "name"){
			element	= rowData.name ? rowData.name  :"";

			if(dropdownType === "driver_tier_level" && rowData.color_code){
				element = <p>{rowData.name} <span className="bg-selected-box" style={{backgroundColor: rowData.color_code}}> &nbsp; </span></p>
			}
		}
		if(actionKey === "count"){
			element	= rowData.count ? rowData.count  :0;
		}
		return element;
	};// end customRender()

	/** Manage add / update modal box event */
	toggleModal(close) {
		this.setState({
			showModel	:	close,
			masterId	: 	null,
			tableKey	:	getRandomString(),
		});
	}

	render() {
		const { showModel , masterId , dropdownType, tableKey} = this.state;

		let actionBtn 	= [];
		let columnsName = [{name: translate("master.name"), selector: "name", sortable: true, defaultSort: true, direction: "asc", width: "70%", customRender: this.customRender}];

		if((Auth.getPermission() !== PROJECT_MANAGER)){
			actionBtn.push({key: "edit"});
			if(dropdownType === "job_title"){
				columnsName.push({name: translate("Total Used"), selector: "count", sortable: true,  width: "25%", customRender: this.customRender});
			}
			columnsName.push({name: translate("system.action"), selector: "", width: "5%", custom_action: this.customAction, actions: actionBtn});

			if(dropdownType === "job_title" || dropdownType === "skill_set") actionBtn.push({key: "delete"});
		}

		let pageStats = {
			title 		:	translate("master.heading."+dropdownType),
			headerLinks	: [
				{name: translate("master.add."+dropdownType), selector: "add", clickEvent: this.customAction }
			],
			columns : columnsName,
			search : [
				{name: translate("master.name"), selector: "name", 	input_type: "text"},
			]
		}

		return <>
			<NormalDataTable pageStats={pageStats} key={tableKey}  apiParm={{model: 'master', method: 'masterListData' , type: dropdownType}} />

			{showModel ?
				<AddEditForm isFormVisible={showModel} toggleModal={this.toggleModal} masterId={masterId} refreshDataTable={this.refreshDataTable} dropdownType={dropdownType}/>
			:null}
		</>
	}
}
export default MasterList;
