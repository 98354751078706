import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from 'react'
import {AsyncPaginate} from 'react-select-async-paginate';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';

import { translate, formatDate } from '../../../helpers/intl_helpers';
import { CALENDAR_DATE_TIME_FORMAT, DATE_RANGE_PICKER_FORMAT, RADIO_YES,RADIO_NO, STATUS_SUCCESS } from '../../../config/global_constants';
import { handleFormValidation, handleFormInputs,  callBackendAPI, customConfirmBox, isWeekendDay, reFormatSuccess, getRandomString  } from '../../../helpers/common';

import DeleteIcon 	 from '../../../assets/images/delete-icon.svg';

export default class AddPtoYardJob extends Component {
	constructor(props) {
        super(props);
        let dateObj 	=	(this.props.dateObj) ? this.props.dateObj :{};
		let dragItem	= 	(dateObj && dateObj.drag_item && (dateObj.drag_item !== undefined)) ? dateObj.drag_item :"";
		let employees	=	[];
		let unpaidEmpList=	[];
		let equipment	=	[];
		let startDate 	=	dateObj.start_date 	?	dateObj.start_date 	:new Date();
		let endDate 	=	dateObj.end_date 	? 	dateObj.end_date 	:new Date();
		let slotType	=	(dateObj && dateObj.slot_type) ? dateObj.slot_type : "";
		let empType		=	(dateObj && dateObj.emp_type) ? dateObj.emp_type : "";
		let isMobile	=	(dateObj && dateObj.isMobileScreen) ? dateObj.isMobileScreen : false;
		let isYard 		=	slotType === "yard" ? true :false;
		let isPTO 		=	slotType === "pto" ? true :false;

		if(isYard && dragItem && dragItem.state && dragItem.state && !dragItem.state.type) employees = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
		if(dragItem && dragItem.state && dragItem.state && (dragItem.state.type === "asset")) equipment = [{_id:dragItem.id,value:dragItem.id,label: dragItem.name}]

		let jobDetails 	=	(this.props.jobDetails) ? this.props.jobDetails :{};

		let isEdit		=	jobDetails.isEdit ? jobDetails.isEdit : false;
		let isCopy		=	jobDetails.isCopy ? jobDetails.isCopy : false;
		let notes		=	jobDetails.notes  ? jobDetails.notes  : null;
		let subject		=	jobDetails.subject  ? jobDetails.subject  : null;
		let jobId		=	isEdit && jobDetails._id ? jobDetails._id :"";

		if(isEdit && jobDetails.unpaid_employee_ids && jobDetails.unpaid_employee_ids.length) empType = "unpaid";
		if(isEdit && jobDetails.paid_employee_list && jobDetails.paid_employee_list.length) empType = "paid";

		if(isPTO && dragItem && dragItem.id && dragItem.name){
			if(empType === "paid") employees = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
			if(empType === "unpaid") unpaidEmpList = [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}];
		}

		if(isEdit && jobDetails.date){
			startDate = new Date(jobDetails.date);
			endDate = new Date(jobDetails.date);
		}

		if(isCopy && jobDetails.selected_date && formatDate(jobDetails.selected_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
			startDate	=	{value: jobDetails.selected_date};
			endDate		=	{value: jobDetails.selected_date};
		}

		if(jobDetails.assets && jobDetails.assets.length){
			jobDetails.assets.forEach(element => {
				equipment.push({_id: element.id, value: element.id, label: element.label});
			});
		}

		if(isYard){
			if(jobDetails.crewMembers && jobDetails.crewMembers.length){
				jobDetails.crewMembers.forEach(element => {
					employees.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}
		}else{
			if(jobDetails.paid_employee_list && jobDetails.paid_employee_list.length && empType === "paid"){
				jobDetails.paid_employee_list.forEach(element => {
					employees.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}

			if(jobDetails.unpaid_employee_list && jobDetails.unpaid_employee_list.length && empType === "unpaid"){
				jobDetails.unpaid_employee_list.forEach(element => {
					unpaidEmpList.push({_id: element.id, value: element.id, label: element.full_name});
				});
			}
		}

		let jobGroupId		=	"";
		let jobGroupColor	=	"#6f0e0e";
		if(isYard && isEdit){
			if(jobDetails && jobDetails.job_group_id) 	 jobGroupId		= 	jobDetails.job_group_id;
			if(jobDetails && jobDetails.job_group_color) jobGroupColor	=	jobDetails.job_group_color;
		}

        this.state = {
			fields: {
                job_group_id	: 	{value: jobGroupId},
                job_group_color	: 	{value: jobGroupColor},
                start_date		: 	{value: startDate},
                end_date		: 	{value: endDate},
                slotType		: 	slotType,
				employees		: 	employees.length  		? 	employees 		:null,
				unpaid_employees: 	unpaidEmpList.length	?	unpaidEmpList 	:null,
				equipment		: 	equipment.length  		?	equipment  		:null,
				notes			: 	{value: notes},
				subject			: 	{value: subject},
				date_range 		: 	[new Date(startDate), new Date(endDate)],
				is_hide_weekend_date: {value: jobDetails.is_hide_weekend_date ? String(jobDetails.is_hide_weekend_date) :  String(RADIO_NO)},
            },
			defaultDateRange: 	[subDays(new Date(), 6), new Date()],
			isYard			: 	isYard,
			isPTO			: 	isPTO,
			errors      	:	{},
            loading     	: 	true,
            submitted		:	false,
			jobId  			: 	jobId,
			isEdit  		: 	isEdit,
			isCopy  		: 	isCopy,
			paidUnpaidVal	:  	empType,
			isMobileScreen	: 	isMobile,
			random_key 		: 	getRandomString(),
        };

        this.handleFormInputs 	= 	this.handleFormInputs.bind(this);
		this.handleFormSubmit 	=  	this.handleFormSubmit.bind(this);
		this.handleChange 		= 	this.handleChange.bind(this);
		this.getDropDownList 	=	this.getDropDownList.bind(this);
		this.closeModal			=  	this.closeModal.bind(this);
    }

	/**
	* For data form api
	*/
	componentDidMount() {
        const { fields, isEdit, isYard} = this.state;

		if(isYard && !isEdit){
			window.showLoader();

			/** Call API */
			callBackendAPI([{model: 'jobs', method: 'generate-group-id'}]).then(response => {
				if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].group_id) {

					this.setState({
						fields: {...fields, ...{job_group_id: {value: response.data[0].group_id}}}
					},()=>{
						window.hideLoader();
					});
				}
			}).catch(err => console.log(err));
		}
	}

	/**
	 * Handle dropdown value state
	 */
	handleChange = (fieldName) => (fieldVal) => {
        let updatedFields = {...this.state.fields, [fieldName]: fieldVal};
		if (fieldName === "date_range") {
            let startDate = fieldVal[0];
            if (startDate) updatedFields['is_hide_weekend_date'] = { value: isWeekendDay(startDate) ? String(RADIO_YES) : String(RADIO_NO) };
        }
        this.setState({fields: updatedFields});
    }; // end handleChange()

	/**
	* For get drop down list
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			const { isPTO, fields: {date_range} } = this.state;

			let apiParams  		=	null;
			let tmpStartDate	= 	(date_range && date_range[0])	?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)	:"";
			let tmpEndDate		=	(date_range && date_range[1])	?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)	:"";
			if(type === "employee"){
				apiParams = { model: 'calendar', method: 'employeeList',is_crew_member: true, add_job: true, is_pto : isPTO, emp_name: inputValue ? inputValue :'', current_page: page,start_date: tmpStartDate, end_date: tmpEndDate};

				// if(isPTO) apiParams = {...apiParams, ...{ start_date: tmpStartDate, end_date: tmpEndDate}};
			}

			if(type === "equipment") apiParams = { model: 'calendar', method: 'assetsList', asset_name: inputValue ? inputValue :'', current_page: page,start_date: tmpStartDate, end_date: tmpEndDate  };

			if(!apiParams) return {options: [], hasMore: false, additional: {page: page}};

            let response = await callBackendAPI([apiParams]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1,type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: false, additional: {page: page,type:type}};
		}
    }// end getDropDownList()

   /**
	* Handle time on change
	*/
   handleTimeChange = (date) => {
		this.setState({
			fields : {...this.state.fields, shift_time: {value: date}}
		});
	} // end handleTimeChange()

	 /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e,isForceAssign){
		if(e) e.preventDefault();

		/** Api Requested Data **/
		const {isYard, isPTO, fields: {employees, equipment, notes, slotType, unpaid_employees, subject, date_range, is_hide_weekend_date, job_group_id, job_group_color}} = this.state;
		let empArr      = [];
		let equipArr    = [];
		let unEmpArr	= [];
		if(employees && employees.length) empArr = employees.map(empArr => empArr._id);
		if(equipment && equipment.length) equipArr = equipment.map(equipArr => equipArr._id);
		if(unpaid_employees && unpaid_employees.length) unEmpArr = unpaid_employees.map(empArr => empArr._id);

		this.setState({
			submitted: true,
			fields : {
				...this.state.fields,
				...{
					employee_id: {value: empArr.length ? empArr : (unEmpArr.length ? unEmpArr : null)},
					equipment_id: {value: equipArr.length ? equipArr : null},
					unpaid_employee_id: {value: unEmpArr.length ? unEmpArr : null}
				}
			}
		},()=>{

			let params = [{
				model				: 	'jobs',
				method				: 	'addYardOrPtoJob',
				job_id		    	: 	this.state.jobId ?	this.state.jobId :"",
				employee_id			: 	(empArr && empArr.length)        	?	empArr	        	:null,
				equipment_id		: 	(equipArr && equipArr.length)       ?	equipArr	        :null,
				unpaid_employee_id	: 	(unEmpArr && unEmpArr.length)       ?	unEmpArr	        :null,
				start_date			: 	(date_range && date_range[0])		?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)		:"",
				end_date			:	(date_range && date_range[1])		?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)		:"",
				notes				:	(notes && notes.value)				?	notes.value			:"",
				subject				:	(subject && subject.value)			?	subject.value		:"",
				type				:	slotType,
				is_hide_weekend_date:   (is_hide_weekend_date && is_hide_weekend_date.value) ? 	is_hide_weekend_date.value 			:"",
				emp_type			:   this.state.paidUnpaidVal,
			}];

			if(isYard){
				params[0]['job_group_id'] 		= 	(job_group_id && job_group_id.value)        ?   job_group_id.value      :"";
				params[0]['job_group_color'] 	=	(job_group_color && job_group_color.value)	?   job_group_color.value	:"";
			}

			if(isForceAssign) params[0]['force_assign'] = true;

			/** Validation Rules **/
			let validations = {
				employee_id : 	{"label": translate('calendar.employees'), "rules" :["select"] },
			}

			if(isYard){
				validations["subject"]  		=  {"label": translate('calendar.subject'), "rules" :["required"] };
				// validations["job_group_id"]  	=  {"label": translate('calendar.job_group_id'), "rules" :["required"] };
				// validations["job_group_color"]  =  {"label": translate('calendar.job_group_color'), "rules" :["select"] };

				if (!empArr.length && !equipArr.length) {
					validations["employee_id"] = { "label": translate('calendar.employees'), "rules": ["select"] };
					validations["equipment_id"] = { "label": translate('calendar.equipment'), "rules": ["select"] };
				} else {
					// Remove the validations if either array has data
					delete validations["employee_id"];
					delete validations["equipment_id"];
				}
			}else if(isPTO){
				if(!empArr.length || !unEmpArr.length){
					validations["employee_id"]  =  {"label": translate('calendar.employees'), "rules" :["select"] };
				}
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, true,true,true).then(response=>{
				if(!response.success){
					if(response.errors && response.data){
						let tmpErrors 	 =  response.data && response.data.message && response.data.message[0] ? response.data.message[0] :{};
						let isEmpOrEquip =	tmpErrors.force_assign;

						this.setState({ errors : response.errors, submitted: false},()=>{
							let message = response.data.message[0].msg;
							if(isEmpOrEquip){
								window.closeModal('addPtoYardJob');
								let confirmation = customConfirmBox(message);
								confirmation.then((confirmed) => {
									if (confirmed) {
										this.handleFormSubmit(null,true)
									}else{
										window.openModal('addPtoYardJob');
									}
								});
							}
						});
					}else if(response.errors){
						this.setState({ errors : response.errors, submitted: false})
					}
				}else{
					/** Call parent function */
					if(this.props.updateEventCalendar) this.props.updateEventCalendar(null,"calendar");
					/** Hide loader */
					this.closeModal();
				}
			}).catch(err => console.log(err));
		})
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('addPtoYardJob');
        this.setState({ submitted: false, errors: {}, fields: {} });
		this.props.handleCloseTaskModal();
	}// end closeModal()

	/**Handle onclick function for remove job */
    handleRemoveJob = (e) =>{
        e.preventDefault();
        const { jobId } = this.state;

        let confirmation = customConfirmBox(translate("calendar.you_want_to_remove_this_job"));
        confirmation.then((confirmed) => {
            if (confirmed) {
                /** Set API request params */
                let apiReq = [ { model: 'jobs', method: 'deleteJob', job_id: jobId},]
                callBackendAPI(apiReq).then(response => {
                    if(response.success && response.data[0].status === STATUS_SUCCESS) {
                        reFormatSuccess(response.data[0].message)
                        this.closeModal()
                        this.props.updateEventCalendar(null,"calendar");
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end handleRemoveItem()

	handleMenuOpen = () => {
        // This ensures the API call is made every time the dropdown is opened
        this.setState({ page: 1 });  // Reset the page if necessary
        this.getDropDownList('', [], { page: 1, type: 'employee' }); // Trigger API call immediately when the dropdown is opened
    };

	render() {
		const {submitted, fields, errors, isEdit, isCopy, paidUnpaidVal, isMobileScreen, isYard, random_key} = this.state;

		return <div className="modal fade crew-modal" id="addPtoYardJob" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="assSubContractorJobLabel" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<button type="button" className="btn-close" onClick={()=>{this.closeModal()}}>X Close</button>
					{(isEdit && isYard) &&
						<button type="button" className="job-delete-btn" onClick={(e)=> { this.handleRemoveJob(e)}}><img src={DeleteIcon} alt="img" /></button>
					}
					<div className="modal-body">
					  	<h3>
							{isYard ?
								<>
									{isCopy ? translate('calendar.clone_yard') :(isEdit ? translate('calendar.update_yard') :translate('calendar.add_yard'))}
								</>
							:
								<>
									{isCopy ? translate('calendar.clone_pto') :(isEdit ? translate('calendar.update_pto') :translate('calendar.add_pto'))}
								</>
							}
						</h3>
						<form onSubmit={(event) => this.handleFormSubmit(event)} noValidate>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								{!isEdit ?
									<div className="row">
										<div className="col-lg-6">
											<div className="form-group" id="modaldialogid">
												<label htmlFor="date" className="form-label">{translate('calendar.select_date')}<span className="error text-danger">*</span></label>
												<DateRangePicker
													value={fields.date_range ? fields.date_range :null}
													format={DATE_RANGE_PICKER_FORMAT}
													size="lg"
													className='form-control input-with-label date-range-picker-common'
													onChange={this.handleChange("date_range")}
													placeholder={translate('reports.search_by_date')}
													cleanable={false}
													ranges={[]}
													editable={false}
													singledatepicker= "true"
													showdropdowns= "true"
													autoupdateinput= "true"
													parente1= "#modaldialogid"
													showOneCalendar={isMobileScreen ? true : false}
												/>
												<span className="error text-danger">{errors["date_range"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className='form-group'>
												<label htmlFor="is_hide_weekend_date" className="form-label">{translate('calendar.is_hide_weekend_date')} </label>
												<div className='form-group checkbox-row order-checkbox-row'>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_YES) ? true :false}/>
														<label className="form-check-label" htmlFor="is_hide_weekend_dateyes">
														{translate('calendar.yes')}
														</label>
													</div>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_NO) ? true :false} />
														<label className="form-check-label" htmlFor="is_hide_weekend_dateno">
														{translate('calendar.no')}
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								:null}

								{isYard &&
									<div className="row">
										{/* <div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="job_group_id" className="form-label">{translate('calendar.job_group_id')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" name='job_group_id' placeholder={translate('calendar.job_group_id')}id='job_group_id' value={fields["job_group_id"] ? fields["job_group_id"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "job_group_id")}/>
												<span className="error text-danger">{errors["job_group_id"]}</span>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="job_group_color" className="form-label">{translate('calendar.job_group_color')} <span className="error text-danger">*</span></label>
												<input type="color" className="form-control" name='job_group_color' placeholder={translate('calendar.job_group_color')} id='job_group_color' value={fields["job_group_color"] ? fields["job_group_color"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "job_group_color")}/>
												<span className="error text-danger">{errors["job_group_color"]}</span>
											</div>
										</div> */}
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="subject" className="form-label">{translate('calendar.subject')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" placeholder="Subject" name='subject' id='subject' value={fields["subject"] && fields["subject"]["value"] ? fields["subject"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "subject")} />
												<span className="error text-danger">{errors["subject"]}</span>
											</div>
										</div>
									</div>
								}

								{(paidUnpaidVal === "" || paidUnpaidVal === "paid") ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												{isYard && (paidUnpaidVal === "") ?
													<label htmlFor="employees" className="form-label">
														{translate('calendar.employees')}
													</label>
												:
													<label htmlFor="employees" className="form-label">
														{translate('calendar.paid_employees')}
													</label>
												}
												<AsyncPaginate
													key={random_key}
													className="custom-react-select"
													value={fields.employees  ? fields.employees : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("employees")}
													isMulti
													additional={{ page: 1, type: "employee"}}
													placeholder={<label htmlFor="employees">{translate('calendar.select_employees')}</label>}
													onMenuOpen={this.handleMenuOpen}
												/>
												<span className="error text-danger">{this.state.errors["employees"]}</span>
												<span className="error text-danger">{this.state.errors["employee_id"]}</span>
											</div>
										</div>
									</div>
								:null}

								{!isYard && (paidUnpaidVal === "unpaid" || paidUnpaidVal === "") ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="unpaid_employees" className="form-label">{translate('calendar.unpaid_employees')}</label>
												<AsyncPaginate
													key={random_key}
													className="custom-react-select"
													value={fields.unpaid_employees  ? fields.unpaid_employees : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("unpaid_employees")}
													isMulti
													additional={{ page: 1, type: "employee"}}
													placeholder={<label htmlFor="unpaid_employees">{translate('calendar.select_employees')}</label>}
													onMenuOpen={this.handleMenuOpen}
												/>
												<span className="error text-danger">{this.state.errors["unpaid_employees"]}</span>
												<span className="error text-danger">{this.state.errors["unpaid_employee_id"]}</span>
											</div>
										</div>
									</div>
								:null}

								{isYard && fields ?
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="equipment" className="form-label">{translate('calendar.equipment')} </label>
												<AsyncPaginate
													className="custom-react-select"
													value={fields.equipment  ? fields.equipment : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("equipment")}
													isMulti
													additional={{ page: 1, type: "equipment"}}
													placeholder={<label htmlFor="equipment">{translate('calendar.select_equipments')}</label>}
												/>
												<span className="error text-danger">{this.state.errors["equipment"]}</span>
												<span className="error text-danger">{this.state.errors["equipment_id"]}</span>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label htmlFor="notes" className="form-label">{translate('calendar.description')} </label>
												<textarea type="notes" className="form-control" name='notes' id='notes' value={fields["notes"] && fields["notes"].value ? fields["notes"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "notes")}></textarea>
												<span className="error text-danger">{this.state.errors["notes"]}</span>
											</div>
										</div>
									</div>
								:null}
							</div>
							<div className='col-xl-12 d-flex'>
								<button type={submitted ? "button" :"submit"} className="btn btn-fill">
									{translate('system.submit')}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
								<button type="button" className="btn btn-outline ms-1" onClick={() => this.closeModal()} data-dismiss="modal">
									{translate('system.close')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	}
}

