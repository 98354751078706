import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import {AsyncPaginate} from 'react-select-async-paginate';

import { callBackendAPI, handleFormInputs} from './../../helpers/common';
import { translate, formatDate, getCurrentWeekDates } from './../../helpers/intl_helpers';
import { STATUS_SUCCESS, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, EXPORT_CSV, EXPORT_PDF, DATERANGE_DATE_TIME_FORMAT, CALENDAR_DATE_TIME_FORMAT } from './../../config/global_constants';

import closeIcon 	 from './../../assets/images/close_icon.svg';
import dataTable 	 from './../../assets/images/data-table.svg';
import dataAsc 		 from './../../assets/images/data-asc.svg';
import dataDesc 	 from './../../assets/images/data-desc.svg';
import arrowDown     from './../../assets/images/arrow_down_blue.svg';
import filterImg 	 from './../../assets/images/filter.svg';
import loaderGif 	 from './../../assets/images/pagination_loader.gif';

class SubcontractorReport extends Component {

	constructor(props) {
        super(props)

        this.state = {
			defaultDateRange: 	[getCurrentWeekDates().monday, getCurrentWeekDates().friday],
			searchStats 	: 	{date_range :[getCurrentWeekDates().monday, getCurrentWeekDates().friday]},
            jobList			: 	[],
			isLoading		:	true,
			locationList	:	[],
			sortStats		:	{},
        };

		this.anchorRef 				=	React.createRef();
		this.getJobList    			=	this.getJobList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.manageSortingEvent		=	this.manageSortingEvent.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getMasterList();
		this.getJobList();
    }

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = {};
			if(type === "subcontractor")  apiReq = 	{model: 'calendar', method: 'employeeList', is_subcontractor: true, emp_name: inputValue ? inputValue : ''};

			if(type === "jobNumber") apiReq = {model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : ''};

			if(type === "workOrder"){
				let jobArr = [];
				if(this.state.searchStats.job_number && this.state.searchStats.job_number.length) 	  jobArr	= this.state.searchStats.job_number.map(element => element.value);

				if(this.state.searchStats.job_number.length === 0) return {options: [], hasMore: true, additional: {page: page, type: type }};

				apiReq = {model: 'calendar', method: 'workOrderList', job_id: jobArr, work_order_number: inputValue ? inputValue : '' };
			}

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getJobList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getJobList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()


	handleChange = (fieldName) => (fieldVal) => {
		let newObj = {[fieldName]: fieldVal};
		let updatedFields = {...this.state.searchStats, ...newObj};

		if(fieldName === "location" && fieldVal.length === 0) 	updatedFields['department'] = null;
		if(fieldName === "job_number") 	updatedFields['work_order_number'] = null;

		this.setState({searchStats: updatedFields});
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	 * Manage sort event
	 */
	manageSortingEvent = (event, field, dir) => {
		event.preventDefault();

		this.setState({
			sortStats	: {key: field, dir: dir},
			currentPage	: 1,
		},()=>{
			this.getJobList();
		});
	};

	/**
	* For get subcontractor job listing
	*/
	getJobList = () => {
		const {searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let apiReq 	=	{ model: 'reports', method: 'jobList', isSubcontractorReport: true, allRecords: true};
			if(sortStats && sortStats.key) apiReq = {...apiReq, ...{sort_by: sortStats.key, sort_direction: sortStats.dir} };
			if(searchStats && Object.keys(searchStats).length){
				apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
				apiReq.employee_id 			=	searchStats.employee_id ? searchStats.employee_id.value :"";
				apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
				apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
				apiReq.fiber_task 			=	searchStats.fiber_task ? searchStats.fiber_task.value :"";
				apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
				apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
				apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

				if(searchStats.location && searchStats.location.length) 	apiReq.location	 	= searchStats.location.map(element => element.value);
                if(searchStats.department && searchStats.department.length) apiReq.department  	= searchStats.department.map(element => element.value);
				if(searchStats.job_number && searchStats.job_number.length) apiReq.job_number_id= searchStats.job_number.map(element => element.value);
				if(searchStats.work_order_number && searchStats.work_order_number.length)	apiReq.work_order_id  	= searchStats.work_order_number.map(element => element.value);
				if(searchStats.subcontractor_id && searchStats.subcontractor_id.length)		apiReq.subcontractor_id = searchStats.subcontractor_id.map(element => element.value);
			}

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						jobList  :	response.data[0].result,
						isLoading: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getJobList()

	/**
	* For manage export event
	*/
	exportTableContent = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq = {model: 'reports', method: 'exportSubcontractorReport', isSubcontractorReport: true, file_type: fileType};
		if(searchStats && Object.keys(searchStats).length){
			apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
			apiReq.employee_id 			=	searchStats.employee_id ? searchStats.employee_id.value :"";
			apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
			apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
			apiReq.fiber_task 			=	searchStats.fiber_task ? searchStats.fiber_task.value :"";
			apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
			apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
			apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

			if(searchStats.location && searchStats.location.length) 	  	apiReq.location	 		= searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 	apiReq.department  		= searchStats.department.map(element => element.value);
			if(searchStats.job_number && searchStats.job_number.length) 	apiReq.job_number_id	= searchStats.job_number.map(element => element.value);
			if(searchStats.work_order_number && searchStats.work_order_number.length) 	apiReq.work_order_id  	= searchStats.work_order_number.map(element => element.value);
			if(searchStats.subcontractor_id && searchStats.subcontractor_id.length) 	apiReq.subcontractor_id	= searchStats.subcontractor_id.map(element => element.value);
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					this.anchorRef.current.href 	=	response.data[0].file_url;
					this.anchorRef.current.download = 	response.data[0].file_name;

					// Programmatically click the anchor element
					this.anchorRef.current.click();
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	/**
	* For manage total in table footer
	*/
	getFooterTotalValue(key){
		let temTotal = 0;
		const {jobList} = this.state;
		if(jobList && jobList.length){
			jobList.map(element=>
				temTotal += element[key] >0 ? element[key] :0
			)
		}
		return temTotal;
	}

	render() {
		const {jobList, searchStats, isLoading,locationList, sortStats} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("reports.subcontractor_report")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="date_range">{translate('reports.date')}</label>
													<div className="col-12">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															editable={false}
															className="form-control input-with-label"
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.handleChange("date_range",[]) }}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-2">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>
												</div>
											</div>
											<div className="col-lg-2">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-2">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="job_number">{translate('reports.job_number')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_job_number"
														key={searchStats.job_number  ? searchStats.job_number : null}
														value={searchStats.job_number  ? searchStats.job_number : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("job_number")}
														additional={{ page: 1, type: "jobNumber"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_job_number')}
													/>
												</div>
											</div>
											<div className="col-lg-2">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="work_order_number">{translate('reports.work_order_number')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_work_order_number"
														key={searchStats.job_number  ? searchStats.job_number : null}
														value={searchStats.work_order_number  ? searchStats.work_order_number : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("work_order_number")}
														additional={{ page: 1, type: "workOrder"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_work_order_number')}
													/>
												</div>
											</div>
											<div className="col-lg-3 align-self-center">
												<div className="advanced-btn reports_advanced_filter">
                                                    <button className='d-flex align-items-center w-100 btn' type="button" data-bs-toggle="collapse" data-bs-target="#advanced-filter" aria-expanded="false" aria-controls="advanced-filter">
                                                    Advanced Filter <img src={arrowDown} alt="img" />
                                                    </button>
                                                </div>
											</div>
											<div className="col-lg-12 collapse mt-3" id="advanced-filter">
												<div className="row">
													<div className="col-lg-4">
														<div className="form-group">
															<label htmlFor="search_by_city" className="form-label mb-1">{translate('reports.city')}</label>
															<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_city')} id='search_by_city' value={searchStats["city"] ? searchStats["city"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"city")} />
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label htmlFor="search_by_state" className="form-label mb-1">{translate('reports.state')}</label>
															<input type="text" className="form-control input-with-label" name='search_by_state' placeholder={translate('reports.search_by_state')} id='search_by_state' value={searchStats["state"] ? searchStats["state"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"state")} />
														</div>
													</div>

													<div className="col-lg-4">
														<div className="form-group">
															<label htmlFor="search_by_zip" className="form-label mb-1">{translate('reports.zip')}</label>
															<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_zip')} id='search_by_zip' value={searchStats["zip"] ? searchStats["zip"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "zip")} />
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<label className='mb-1 form-label' htmlFor="search_by_subcontractor">{translate('reports.subcontractor')}</label>
															<AsyncPaginate
																className="custom-react-select"
																inputId="search_by_subcontractor"
																value={searchStats.subcontractor_id  ? searchStats.subcontractor_id : null}
																loadOptions={this.getDropDownList}
																onChange={this.handleChange("subcontractor_id")}
																additional={{ page: 1, type: "subcontractor"}}
																isMulti
																isClearable={true}
																placeholder={translate('reports.search_by_subcontractor')}
															/>
														</div>
													</div>
												</div>
											</div>

											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row">
						<div className="col-lg-12 text-end">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
								<a href="/" ref={this.anchorRef} className='d-none' target='_new'>{translate("reports.export_as_csv")}</a>
							</div>
						</div>
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									<th width='10%'>
										{translate('job_report.start_date')}
									</th>
									<th width='10%'>
										{translate('job_report.end_date')}
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "job_number", (sortStats && sortStats.key === "job_number" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.job_number')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "job_number") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "job_number" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "project_manager_id", (sortStats && sortStats.key === "project_manager_id" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.project_manager')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "project_manager_id") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "project_manager_id" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "work_order_number", (sortStats && sortStats.key === "work_order_number" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.work_order')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "work_order_number") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "work_order_number" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='15%'>
										{translate('reports.subcontractor')}
									</th>
									<th width='15%'>
										{translate('job_report.flagger_subcontractor_id')}
									</th>
									<th width='20%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "jobsite_address_street", (sortStats && sortStats.key === "jobsite_address_street" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.jobsite_address')}
										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "jobsite_address_street") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "jobsite_address_street" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan="8" className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								:
									jobList && jobList && jobList.length  ?
										jobList.map((row, rowIndex)=>
											<tr key={"row"+rowIndex}>
												<td>{row.start_date ? formatDate(row.start_date , DATERANGE_DATE_TIME_FORMAT) :"-"}</td>
												<td>{row.end_date ? formatDate(row.end_date , DATERANGE_DATE_TIME_FORMAT) :"-"}</td>
												<td>{row.job_number ? row.job_number :"-"}</td>
												<td>{row.project_manager_id ? row.project_manager_id :"-"}</td>
												<td>{row.work_order_number ? row.work_order_number :"-"}</td>
												<td>{row.subcontractor_id ? row.subcontractor_id :"-"}</td>
												<td>{row.flagger_subcontractor_id ? row.flagger_subcontractor_id :"-"}</td>
												<td>{row.jobsite_address && row.jobsite_building ? row.jobsite_building+','+ row.jobsite_address : (row.jobsite_address ? row.jobsite_address : "-")}</td>
											</tr>
										)
								:
									<tr>
										<td colSpan="8" className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</>
	}
}
export default SubcontractorReport;
